<template>
  <div>
    <ul class="flex wrap">
      <li v-for="items in list" :key="items.id" @click="dataToDetail(items.id)">
        <img :src="items.activityImg" alt="" />
        <div>
          <div>{{ items.activityName }}</div>
          <div>
            <img src="../../../../assets/pc/images/matching/time_icon.png" alt="" />
            <span>活动时间：{{ timeIntercept(items.activityStartTime) }}至{{
                timeIntercept(items.activityEndTime)
              }}</span>
          </div>
          <div class="flex" style="justify-content: left;">
            <img src="../../../../assets/pc/images/matching/time_icon.png" alt="" />
            <span>活动地点：{{ items.activityAdress }}</span>
          </div>
        </div>
        <div v-if="items.isRecommend === 1" class="mark">推荐</div>
        <div v-if=" items.activityFlg" class="mark-end">
          活动已结束
        </div>
        <div v-if="items.countdownFlg" class="mark-time flex">
          距离报名截止
          <!-- <span
            >{{ items.countdown[0] }}天 {{ items.countdown[1] }}:{{
              items.countdown[1]
            }}:{{ items.countdown[3] }}</span
          > -->
          <span>{{ applicationTime(TimeInterval(items.countdown)) }}</span>
        </div>
      </li>
    </ul>
    <el-pagination v-if="paginationFlg" background layout="prev, pager, next" :page-size="pageSize" :total="pageListTotal" :current-page="currentPage" style="text-align: center"  @current-change="handleCurrentChange">
      <!-- @current-change="getTongzhiListData" -->
    </el-pagination>
  </div>
</template>

<script>
import { request } from "@/network";
import { mapMutations } from "vuex";
export default {
  components: {},
  props: {
    pageListTotal: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    activityList: {
      type: Array,
      default: () => [],
    },
    paginationFlg: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      list: [],
      timer: null
    };
  },
  watch: {
    activityList: {
      handler(newValue, oldValue) {
        this.init(newValue);
      },
      deep: true,
    },
  },
  created() { },
  async mounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.init(this.activityList);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    init(data) {
      this.list = JSON.parse(JSON.stringify(data));
      this.list.forEach((item, index) => {
        item.countdown = Date.parse(new Date(item.applicationEndTime));
        item.countdownFlg = this.applicationTime(
          this.TimeInterval(item.countdown)
        );
        item.activityFlg =
          Date.parse(new Date(item.activityEndTime)) -
            parseInt(new Date().getTime()) >
            0
            ? false
            : true;
        --item.countdown;
      });
      this.countdownTime();
    },
    dataToDetail (clickId) {
      this.$router.push({
          name: 'activityDetails',
          query: {
            clickId
          }
        });
    },
    applicationTime(data) {
      let newData = new Date().getTime() / 1000;
      let endData =
        new Date(data.replace(new RegExp("-", "gm"), "/")).getTime() / 1000;
      let d,
        h,
        m,
        s = "";
      d = parseInt((endData - newData) / 60 / 60 / 24);
      h = parseInt(((endData - newData) / 60 / 60) % 24);
      m = parseInt(((endData - newData) / 60) % 60);
      s = parseInt((endData - newData) % 60);
      if (
        (d < 3 || (d === 3 && h === 0 && m === 0 && s === 0)) &&
        endData - newData > 0
      ) {
        h = h < 10 ? "0" + h : h;
        m = m < 10 ? "0" + m : m;
        s = s < 10 ? "0" + s : s;
        return `${d}天 ${h}:${m}:${s}`;
      } else {
        return "";
      }
    },
    TimeInterval(inputTime) {
      var date = new Date(inputTime);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    countdownTime() {
      let _this = this;
      _this.timer = setInterval(() => {
        _this.list.forEach((item) => {
          if (item.countdown - parseInt(new Date().getTime()) > 0) {
            --item.countdown;
            item.countdownFlg = this.applicationTime(
              this.TimeInterval(item.countdown)
            );
          } else {
            item.countdownFlg = "";
            item.endFlg = true;
          }
        });
        _this.$forceUpdate();
      }, 1000);
    },
    timeIntercept(time) {
      if (!time) {
        return;
      }
      let data = "";
      time.split("-").forEach((item, index) => {
        if (index === 1) {
          data += item + "月";
        } else if (index === 2) {
          data += item.split(" ")[0] + "日";
        }
      });
      return data;
    },
    handleCurrentChange (val) {
      this.$emit('change', val);
    },
  
  },
};
</script>

<style lang="less" scoped>
ul {
  width: 1290px;
  justify-content: start;
  li {
    width: 338px;
    margin-right: 90px;
    height: 348px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 100);
    border: 1px solid rgba(97, 119, 154, 0.2);
    margin-bottom: 50px;
    position: relative;
    cursor: pointer;
    .mark {
      position: absolute;
      right: 0;
      top: 0;
      width: 61px;
      height: 32px;
      line-height: 32px;
      border-bottom-left-radius: 10px;
      background-color: rgba(249, 63, 63, 100);
      color: rgba(255, 255, 255, 100);
      font-size: 16px;
      text-align: center;
      font-family: Microsoft Yahei;
    }
    .mark-end {
      width: 100%;
      height: 200px;
      line-height: 200px;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 0px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 26px;
      font-family: PingFangSC-regular;
    }
    .mark-time {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      position: absolute;
      left: 0;
      top: 150px;
      border-radius: 0px;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 18px;
      font-family: PingFangSC-regular;
      > span {
        color: rgba(255, 31, 31, 95);
        font-size: 21px;
        font-family: PingFangSC-regular;
        margin-left: 25px;
      }
    }
    > img {
      width: 100%;
      height: 200px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    > div:nth-of-type(1) {
      padding: 13px 20px 0px 20px;
      > div:nth-of-type(1) {
        color: rgba(0, 0, 0, 0.9);
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      > div:nth-of-type(2) {
        margin-top: 13px;
        > img {
          width: 18px;
          height: 18px;
          margin-right: 6px;
        }
        > span {
          color: rgba(0, 0, 0, 0.6);
          font-size: 14px;
        }
      }
      > div:nth-of-type(3) {
        margin-top: 5px;
        > img {
          width: 18px;
          height: 18px;
          margin-right: 6px;
        }
        > span {
          color: rgba(0, 0, 0, 0.6);
          font-size: 14px;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  // li:nth-child(3n + 3) {
  //   margin-right: 0px;
  // }
  li:hover {
    box-shadow: 0px 2px 10px 0px rgba(194, 194, 194, 100);
  }
}
</style>